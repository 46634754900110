
import { createStyles } from "../../styles"

export const styles = createStyles({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  loadingItem: {
    color: '#777',
    fontSize: '14px',
    fontWeight: 600
  },
  loadingText: {
    paddingLeft: '8px'
  }
})