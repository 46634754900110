
import { colors } from '../../colors'
import { createStyles } from '../../styles'

const titleSize = '22px'
const titleWeight = 500

export const styles = createStyles({
  main: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    minHeight: '800px',
    minWidth: '360px'
  },

  //LEFT
  left: {
    display: 'flex',
    flex: 1, 
    background: 'rgb(210, 210, 210)',
    flexDirection: 'column'
  },
  leftTop: {
    fontWeight: 700,
    color: colors.darkGrey,
  },
  leftUpper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    minHeight: '0'
  },
  leftLower: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  infoHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  leftBottom: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  sliderGroups: {
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  verticalSlidersContainer: {
    height: '100%',
    display: 'flex',
    padding: '8px'
  },
  verticalSlider: {
    marginRight: "15px",
  },
  sliderIcon: {
    height: '35px',
    width: 'auto'
  },
  verticalSliderHeader: {
    fontSize: titleSize,
    fontWeight: titleWeight,
    textAlign: 'center',
  },
  verticalSliderGroup: {
    height: '290px',
    display: 'flex',
    flexDirection: 'column',
  },
  horizontalSliderHeader: {
    paddingLeft: '36px',
    fontWeight: 'bold'
  },
  horizontalSliderGroup: {
    marginBottom: '40px',
    margin: '8px'
  },
  horizontalSlider: {
    marginBottom: '10px',
  },
  horizontalSlidersContainer: {
    marginLeft: "20px", 
    marginRight: "20px", 
    marginBottom: "10px"
  },
  infoButtons: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'auto',
    paddingBottom: '32px'
  },
  infoButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
  },
  infoButtonsTitle: {
    fontSize: titleSize,
    fontWeight: titleWeight,
    marginBottom: '16px',
    color: colors.darkGrey
  },
  map: {
    height: '100%',
    width: '100%'
  },
  hand: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resetButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '15px',
    backgroundColor: 'rgb(176 130 17)',
    boxShadow: 'none',
    color: 'white'
  },

  //RIGHT
  right: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: 'white',
  },
  rightMiddle: {
    display: 'flex',
    justifyContent: 'start',
    alignContent: 'space-between'
  },
  rightLower: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  chartTitle: {
    fontSize: titleSize,
    fontWeight: titleWeight,
    color: colors.darkGrey
  },
  chartSubTitle: {
    color: colors.darkGrey
  },
  dateSliderContainer : {
    width: '100%'
  },
  dateTitle: {
    fontSize: titleSize,
    marginBottom: '16px',
    fontWeight: titleWeight,
    color: colors.darkGrey
  },

  //OTHER
  dialog: {
    display: 'flex',
  },
  dialogLeft: {
    flex: 1
  },
  dialogRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  dialogRightTop: {
    fontSize: '24px',
    fontWeight: 500
  },
  dialogImage: {
    maxWidth: '800px'
  },
  dialogCloseButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    zIndex: 1000
  },
  infoAndReset: {
    display: 'flex',
    padding: '8px'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '128px 32px 32px 32px',
    color: '#777'
  },
  noDataBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#777',
    fontSize: '14px',
    fontWeight: 600
  },
  loadingLabel: {
    paddingLeft: '16px'
  }
})