import { RawDataPointConsumption, RawDataPointProduction } from "../../api/EnergiDataApi"
import { DataPointProduction, DataPointConsumption } from "../chart/Chart.types"
import { BundleMode, DateMode, RawDataBundle, SelectedDate } from "./Dashboard.types"
import sub from 'date-fns/sub'
import format from 'date-fns/format'

const STONE_STORAGE_CAPACITY_HOUR = 10
const STONE_STORAGE_CAPACITY_DAY = 200
const STONE_STORAGE_CAPACITY_MONTH = 2500

export const bundleRawData = <K extends RawDataPointConsumption | RawDataPointProduction> (selectedDate: SelectedDate, bundleMode: BundleMode, data: K[]): RawDataBundle[] => {
    let dateFilter: (d: K) => boolean
    let getTimeUnit: (d: K) => number
    
    switch(bundleMode) {
      case BundleMode.HOURS:
        dateFilter = (d: K) => {
            const date = new Date(d.HourDK)
            return date.getFullYear() === selectedDate.year && date.getMonth() + 1 === selectedDate?.month && date.getDate() === selectedDate?.day
        }
        getTimeUnit = (d: K) => new Date(d.HourDK).getHours()
        break
      case BundleMode.DAYS:
        dateFilter = (d: K) => {
            const date = new Date(d.HourDK)
            return date.getFullYear() === selectedDate.year && date.getMonth() + 1 === selectedDate?.month
        }
        getTimeUnit = (d: K) =>  new Date(d.HourDK).getDate()
        break
      case BundleMode.MONTHS:
        dateFilter = (d: K) => {
          const date = new Date(d.HourDK)
          return date.getFullYear() === selectedDate.year
        }
        getTimeUnit = (d: K) =>  new Date(d.HourDK).getMonth() + 1
        break

      case BundleMode.YEARS:
        dateFilter = (d: K) => true
        getTimeUnit = (d: K) =>  new Date(d.HourDK).getFullYear()
        break
      default:
        return []
    }

    const bundles = data
    .filter(dateFilter)
    .reverse()
    .reduce((bundles: K[][], current: K) => {
        const timeUnit = getTimeUnit(current)
        if (!bundles[timeUnit]) {
          bundles[timeUnit] = []
        }
        bundles[timeUnit].push(current)
        return bundles
    }, [])

    return Object.keys(bundles).map((timeUnit: string) => ({
      timeUnit,
      rawDataPoints: bundles[Number(timeUnit)]
    }))
}

export const rawProductionsToDataPoint = (timeLabel: string, data: RawDataPointProduction[]): DataPointProduction => {
    const offshoreWindGe = data.reduce((n, { OffshoreWindGe100MW_MWh }) => n + (OffshoreWindGe100MW_MWh ?? 0) , 0)
    const offshoreWindLt = data.reduce((n, { OffshoreWindLt100MW_MWh }) => n + (OffshoreWindLt100MW_MWh ?? 0), 0)
    const offshoreWind = offshoreWindGe + offshoreWindLt
    const onshoreWind = data.reduce((n, { OnshoreWindMWh }) => n + (OnshoreWindMWh ?? 0), 0)
    const solarM = data.reduce((n, { SolarMWh }) => n + (SolarMWh ?? 0), 0)
    const thermalPower = data.reduce((n, { ThermalPowerMWh }) => n + (ThermalPowerMWh ?? 0), 0)
    const combinedWind = offshoreWind + onshoreWind
    return {
      solar: solarM,
      offshoreWind,
      onshoreWind,
      thermalPower,
      timeLabel,
      combinedWind
    }
}

export const rawConsumptionsToDataPoint = (timeLabel: string, data: RawDataPointConsumption[]): DataPointConsumption => {
    const consumption = (data.reduce((n, { ConsumptionkWh }) => n + (ConsumptionkWh ?? 0), 0)) / 1000 //kwh -> mwh
    return {
      consumption,
      timeLabel
    }
}

export const countSelfSufficientHours = (consumption: RawDataPointConsumption[], production: RawDataPointProduction[], selectedDate: SelectedDate, dateMode: DateMode): number => {
  let dateFilterFunc: (d: RawDataPointConsumption | RawDataPointProduction) => boolean

  if(dateMode === DateMode.DAY) {
    dateFilterFunc = (d: RawDataPointConsumption | RawDataPointProduction) => {
      const date = new Date(d.HourDK)
      return date.getFullYear() === selectedDate.year && date.getMonth() + 1 === selectedDate?.month && date.getDate() === selectedDate?.day
    }
  } else if(dateMode === DateMode.MONTH) {
    dateFilterFunc = (d: RawDataPointConsumption | RawDataPointProduction) => {
      const date = new Date(d.HourDK)
      return date.getFullYear() === selectedDate.year && date.getMonth() + 1 === selectedDate?.month
    }
  } else {
    dateFilterFunc = (d: RawDataPointConsumption | RawDataPointProduction) => {
      const date = new Date(d.HourDK)
      return date.getFullYear() === selectedDate.year
    }
  }

  production = production.filter(dateFilterFunc).reverse()
  consumption = consumption.filter(dateFilterFunc).reverse()

  // Consumption data contains datapoints for multiple "Branche" types at identical timestamps so these needs to be accumulated
  const accumulatedConsumptions = []
  for (let i = 0; i < consumption.length; i++) {
    const deepCopyConEle = {
      HourUTC: consumption[i].HourUTC,
      HourDK: consumption[i].HourDK,
      MunicipalityNo: "",
      Branche: "",
      ConsumptionkWh: consumption[i].ConsumptionkWh
    }
    if(accumulatedConsumptions.length === 0) {
      // Push deep copy of the first element
      accumulatedConsumptions.push(deepCopyConEle)
    }
    else {
      const accumulatedConEle = accumulatedConsumptions[accumulatedConsumptions.length - 1]
      if(accumulatedConEle.HourDK.localeCompare(deepCopyConEle.HourDK) === 0) {
        accumulatedConEle.ConsumptionkWh = accumulatedConEle.ConsumptionkWh + deepCopyConEle.ConsumptionkWh
      } else {
        accumulatedConsumptions.push(deepCopyConEle)
      }
    }
  }
  
  let selfSufficiencyHours:number = 0
  for (let i = 0; i < accumulatedConsumptions.length; i++) {
    const conEle:RawDataPointConsumption = accumulatedConsumptions[i]

    // Find index of the production datapoint that matches in time
    let prodInd: number = -1
    for (let k = 0; k < production.length; k++) {
      const ele = production[k]
      if(ele.HourDK.localeCompare(conEle.HourDK) === 0) {
        prodInd = k
        break;
      }
      // Stop searching as time of production data is ahead of consumption data
      if(ele.HourDK.localeCompare(conEle.HourDK) > 0) {
        break;
      }
    }

    if(prodInd > -1) {
      const totProd = production.splice(prodInd, 1).map(p => (p.OffshoreWindGe100MW_MWh ?? 0) + (p.OffshoreWindLt100MW_MWh ?? 0) + (p.OnshoreWindMWh ?? 0) + (p.SolarMWh ?? 0) + (p.ThermalPowerMWh ?? 0))[0]
      const totCon = conEle.ConsumptionkWh / 1000 //kwh -> mwh
      selfSufficiencyHours = selfSufficiencyHours + (totProd >= totCon ? 1 : 0)
    }
  }

  return selfSufficiencyHours
}

export const sumRawBundlesToDataPoints = <T extends DataPointConsumption | DataPointProduction, K extends RawDataPointConsumption | RawDataPointProduction> (sumFunc: (timeLabel: string, data: K[]) => T, rawDataBundles: RawDataBundle[], timeUnitOffset: number = 0, fillMissingDataPoints: boolean = true): T[] => 
{
  const dataBundle = rawDataBundles.map(rdb => sumFunc((parseInt(rdb.timeUnit) + timeUnitOffset).toString(), rdb.rawDataPoints as any))

  return fillMissingDataPoints ? fillMissingTimes(dataBundle) : dataBundle
}

export const isRawDataPointConsumption = (rawDataPoint: RawDataPointConsumption | RawDataPointProduction): rawDataPoint is RawDataPointConsumption => (rawDataPoint as RawDataPointConsumption).ConsumptionkWh !== undefined

export const isDataPointProduction = (dataPoint: DataPointConsumption | DataPointProduction): dataPoint is DataPointProduction => (dataPoint as DataPointProduction).solar !== undefined

export const fillMissingTimes = <T extends DataPointConsumption | DataPointProduction> (dataPoints: T[]) => {
  const toReturn: T[] = []
  
  for (let index = 0; index < dataPoints.length; index++) {
    const dp = dataPoints[index]
    toReturn.push(dp)
    if(index + 1 < dataPoints.length && !isNaN(Number(dataPoints[index+1].timeLabel)) && !isNaN(Number(dp.timeLabel)) && Number(dataPoints[index+1].timeLabel) > Number(dp.timeLabel)+1) {
      if(isDataPointProduction(dp)) {
        toReturn.push({
          solar: undefined,
          offshoreWind: undefined,
          onshoreWind: undefined,
          thermalPower: undefined,
          timeLabel: (Number(dp.timeLabel)+1).toString(),
          combinedWind: undefined,
        } as T)
      } else {
        toReturn.push({ 
          consumption: undefined,
          timeLabel: (Number(dp.timeLabel)+1).toString()
        } as T)
      }
    }
  }
  
  return toReturn
}

export  const normalizePercentage = (val: number) => val/100

export const getDateMode = (date: SelectedDate): DateMode => {
  const isDay = date.day !== undefined && date.month !== undefined && date.year !== undefined
  const isMonth = date.day === undefined && date.month !== undefined && date.year !== undefined
  const isYear = date.day === undefined && date.month === undefined && date.year !== undefined
  if (isDay) {
    return DateMode.DAY
  } else if (isMonth) {
    return DateMode.MONTH
  } else if (isYear) {
    return DateMode.YEAR
  }
  return DateMode.UNKNOWN
}

export const selectedDateToBundleMode = (selectedDate: SelectedDate) => {
  const dateMode = getDateMode(selectedDate)
  return dateMode === DateMode.DAY ? BundleMode.HOURS : dateMode === DateMode.MONTH ? BundleMode.DAYS : BundleMode.MONTHS
}

export const calculateStoneStorageValue = (mode: DateMode, numberOfFacilities: number) => {
  switch(mode) {
    case DateMode.DAY:
      return STONE_STORAGE_CAPACITY_HOUR * numberOfFacilities
    case DateMode.MONTH:
      return STONE_STORAGE_CAPACITY_DAY * numberOfFacilities
    case DateMode.YEAR:
      return STONE_STORAGE_CAPACITY_MONTH * numberOfFacilities
    default:
      return 0
  }
}

export const calculateTotal = (consumption: DataPointConsumption[], production: DataPointProduction[], stoneStorageMW = 0, extraConsumptionMW = 0, extraProductionMW = 0 ): number => 
  (production.reduce((prev, curr) => prev + ((curr?.solar ?? 0) + (curr?.combinedWind ?? 0)), 0) + stoneStorageMW + extraProductionMW - consumption.reduce((prev, curr) => prev + (curr?.consumption ?? 0), 0) - extraConsumptionMW)

export const getInitialDate = (today: Date = new Date()): SelectedDate => {
  const twoMonthsAgo = sub(today, {
    months: 2
  })
  return {
    year: twoMonthsAgo.getFullYear(),
    month: twoMonthsAgo.getMonth() + 1,
    day: twoMonthsAgo.getDate()
  }
}

export const getQuickFetchDate = (initialDate: SelectedDate): string | undefined => {
  if (initialDate.day === undefined || initialDate.month === undefined) {
    return undefined
  }
  const quickDate = sub(new Date(initialDate.year, initialDate.month - 1, initialDate.day), {
    months: 2
  })
  return format(quickDate, 'yyyy-MM-dd') + 'T00:00'
}