export interface RawDataPointProduction {
    HourUTC: string   
    HourDK: string 
    MunicipalityNo: string
    SolarMWh: number | null
    OffshoreWindLt100MW_MWh: number | null
    OffshoreWindGe100MW_MWh: number | null
    OnshoreWindMWh: number | null
    ThermalPowerMWh: number | null
}
  
export interface RawDataPointConsumption {
    HourUTC: string
    HourDK: string
    Branche: string
    MunicipalityNo: string
    ConsumptionkWh: number
}

export const getProductionData = async (startDate: string): Promise<RawDataPointProduction[]> => {
    const response = await fetch(`https://api.energidataservice.dk/dataset/ProductionMunicipalityHour?offset=0&start=${startDate}&filter=%7B%22MunicipalityNo%22:%22360%22%7D&sort=HourUTC%20DESC&timezone=dk`)
    return (await response.json()).records
}

export const getConsumptionData = async (startDate: string): Promise<RawDataPointConsumption[]> => {
    const response = await fetch(`https://api.energidataservice.dk/dataset/ConsumptionIndustry?offset=0&start=${startDate}&filter=%7B%22MunicipalityNo%22:%22360%22%7D&sort=HourUTC%20DESC&timezone=dk`)
    return (await response.json()).records
}