import { createStyles } from "../../styles";

export const styles = createStyles({
slidersContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
},
slider: {
    margin: '8px',
},
sliderIcon: {
    height: '35px',
    width: 'auto'
  }
})