import { DateMode } from "../dashboard/Dashboard.types"


export interface ConsumptionSlidersProps {
    onHeatSupplyChanged: (value: number) => void
    onTransportChanged: (value: number) => void
    onPowerToXChanged: (value: number) => void
    onMiscellaneousChanged: (value: number) => void
    dateMode: DateMode,
    forecast: Forecast,
    steps?: number,
    isBigScreen?: boolean
}
  
export interface SliderConf {
    max: number,
    stepSize: number
}

export enum Forecast {
    start = "start",
    mid = "mid",
    end = "end"
}