import { RawDataPointConsumption, RawDataPointProduction } from "../../api/EnergiDataApi"
import { ReactNode } from "react"
//TODO: Fetch SelectedDate type from dataviz-lib
export interface SelectedDate {
    day?: number
    month?: number
    year: number
}
  
export interface RawDataBundle {
    timeUnit: string,
    rawDataPoints: (RawDataPointConsumption | RawDataPointProduction)[]
}

export enum BundleMode {
    HOURS = "hours",
    DAYS = "days",
    MONTHS = "months",
    YEARS = "years"
}

export enum DateMode {
    DAY,
    MONTH,
    YEAR,
    UNKNOWN
}
export interface DialogData {
    title: string,
    sections: {title: string, body: string}[],
    content?: ReactNode
    img?: ReactNode
}
  
export enum Texts {
    PRODUCTION,
    STORAGE,
    CONSUMPTION
}