import { createTheme, ThemeProvider } from "@mui/material"
import { Dashboard } from "./components/dashboard/Dashboard"
import { colors } from './colors'
import { StartPage } from "./components/startPage/StartPage"
import { useIdleTimer } from "react-idle-timer"

const theme = createTheme({
  palette: {
    primary: {
      main: colors.darkGreen
    }
  }
})

export const App = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const startPage = urlParams.get('startPage')
  const startPageLink = window.location.origin + window.location.pathname + '?startPage=true'

  const onIdle = () => {
    if (startPage === 'false') {
      window.location.href = startPageLink
    }
  }

  useIdleTimer({
    timeout: 90000,
    onIdle 
  })

  return (
  <ThemeProvider theme={theme}>
  {startPage === 'true' 
    ?
    <StartPage />
    :
    <Dashboard backLink={startPage !== null ? startPageLink : undefined} />
  }
  </ThemeProvider>)
}
