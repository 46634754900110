
import { createStyles } from '../../../styles'

export const styles = createStyles({
  logo: {
    maxWidth: '80px',
    maxHeight: '24px',
    marginLeft: '16px'
  },
  logos: {
    marginTop: '32px',  
    float: 'right'
  },
  closeButton: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    zIndex: 1000
  }
})
