
import { createStyles } from '../../styles'

export const styles = createStyles({
  container: { 
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100dvh',
    width: '100dvw' 
  },
  inner: {
    height: '90dvh',
  },
  buttonContainer: {
    borderRadius: '16px',
    height: '100%'
  },
  link: {
    height: '100%'
  },
  linkImg: {
    height: '100%'
  }
})
