
import { createStyles } from '../../styles'

export const styles = createStyles({
  chartYLabel: {
    color: '#444',
    paddingLeft: '32px',
    marginTop: '14px',
    position: 'absolute',
    marginLeft: '32px'
  }
})
