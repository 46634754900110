import { styles } from "./ConsumptionSliders.style"
import { ReactComponent as PowerToX } from './../../img/Power-to-X.svg'
import { ReactComponent as HeatSupply } from './../../img/Varmeforsyning.svg'
import { ReactComponent as Transport } from './../../img/Transport.svg'
import { useCallback, useEffect, useState } from "react"
import { HorizontalModeValuePosition, IconSlider, LabelPosition, SliderOrientation } from "dataviz-lib"
import { Forecast, ConsumptionSlidersProps, SliderConf } from "./ConsumptionSliders.types"
import { DateMode } from "../dashboard/Dashboard.types"
import { valueWithDecimalsAndLocale } from "../../util/format-utils"

//MWh - fra Sweco analysen
const FORECASTS = {
  powerToX: new Map<Forecast, number>([[Forecast.start, 1925000], [Forecast.mid, 3093750], [Forecast.end, 4262500]]),
  heatSupply: new Map<Forecast, number>([[Forecast.start, 38220], [Forecast.mid, 52159], [Forecast.end, 61498]]),
  transport: new Map<Forecast, number>([[Forecast.start, 23341], [Forecast.mid, 42620], [Forecast.end, 66242]]),
  miscellaneous: new Map<Forecast, number>([[Forecast.start, 54345], [Forecast.mid, 128705], [Forecast.end, 250155]])
}

export const ConsumptionSliders = ({onHeatSupplyChanged, onTransportChanged, onPowerToXChanged, onMiscellaneousChanged, dateMode, forecast, isBigScreen = true, steps = 10}: ConsumptionSlidersProps) => {
  const [heatSupplyValue, setHeatSupplyValue] = useState<number>(0)
  const [transportValue, setTransportValue] = useState<number>(0)
  const [powerToXValue, setPowerToXValue] = useState<number>(0)
  // const [miscellaneousValue, setMiscellaneousValue] = useState<number>(0)

  const [heatSupplyConf, setHeatSupplyConf] = useState<SliderConf>({max: 0, stepSize: 0})
  const [transportConf, setTransportConf] = useState<SliderConf>({max: 0, stepSize: 0})
  const [powerToXConf, setPowerToXConf] = useState<SliderConf>({max: 0, stepSize: 0})
  // const [miscellaneousConf, setMiscellaneousConf] = useState<SliderConf>({max: 0, stepSize: 0})

  const valueDisplay = {
    valueFormatter: (val: number | number[]) => '+' + valueWithDecimalsAndLocale(val as number, 0) + ' MWh',
    horizontalModePos: isBigScreen ? HorizontalModeValuePosition.end : HorizontalModeValuePosition.label
  }

  const periodValueFromTotalValue = useCallback((value: number): number | undefined => {
    switch(dateMode) {
      case DateMode.DAY:
        return value/8760;
      case DateMode.MONTH:
        return value/365
      case DateMode.YEAR:
        return value/12
      default:
        return undefined
    }
  }, [dateMode])

  useEffect(() => {
    const powerToXPeriodValue = (periodValueFromTotalValue(FORECASTS.powerToX.get(forecast) as number) ?? 0)
    setPowerToXConf({max: Math.round(powerToXPeriodValue), stepSize: Math.ceil(powerToXPeriodValue/steps)})
    setPowerToXValue(0)

    const heatSupplyValue = (periodValueFromTotalValue(FORECASTS.heatSupply.get(forecast) as number) ?? 0)
    setHeatSupplyConf({max: Math.round(heatSupplyValue), stepSize: Math.ceil(heatSupplyValue/steps)})
    setHeatSupplyValue(0)

    const transportPeriodValue = (periodValueFromTotalValue(FORECASTS.transport.get(forecast) as number) ?? 0)
    setTransportConf({max: Math.round(transportPeriodValue), stepSize: Math.ceil(transportPeriodValue/steps)})
    setTransportValue(0)

    // This should be broken up into the specific sub catagories
    // const miscellaneousPeriodValue = (periodValueFromTotalValue(FORECASTS.miscellaneous.get(forecast) as number) ?? 0)
    // setMiscellaneousConf({max: Math.round(miscellaneousPeriodValue), stepSize: Math.ceil(miscellaneousPeriodValue/steps)})
    // setHeatSupplyValue(0)
    
  }, [dateMode, periodValueFromTotalValue, forecast, steps])

  return (
    <div style={{...styles.slidersContainer, flexDirection: isBigScreen ? 'row' : 'column',  height: isBigScreen ? '100%' : 'auto', width: isBigScreen ? 'auto' : '100%'}}>
      <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '120px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={powerToXConf.stepSize}
          valueInterval={{min: 0, max: powerToXConf.max}}
          value={powerToXValue}
          label={{value:'Power-to-X', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon={isBigScreen ? <PowerToX style={styles.sliderIcon}/> : undefined}
          onChange={(event, value) => {
            setPowerToXValue(value as number)
            onPowerToXChanged(value as number)
          }} />
      </div>
      <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '120px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={heatSupplyConf.stepSize}
          valueInterval={{min: 0, max: heatSupplyConf.max}}
          value={heatSupplyValue}
          label={{value:'Varmeforsyning', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon={isBigScreen ? <HeatSupply style={styles.sliderIcon}/> : undefined}
          onChange={(event, value) => {
            setHeatSupplyValue(value as number)
            onHeatSupplyChanged(value as number)
          }} />
      </div>
      <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '120px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={transportConf.stepSize}
          valueInterval={{min: 0, max: transportConf.max}}
          value={transportValue}
          label={{value:'Transport', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon={isBigScreen ? <Transport style={styles.sliderIcon}/> : undefined}
          onChange={(event, value) => {
            setTransportValue(value as number)
            onTransportChanged(value as number)
          }} />
      </div>
      {/* This should be broken up into the specific sub catagories */}
      {/* <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '104px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={miscellaneousConf.stepSize}
          valueInterval={{min: 0, max: miscellaneousConf.max}}
          value={miscellaneousValue}
          label={{value:'Andet', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon={icon}
          onChange={(event, value) => {
            setMiscellaneousValue(value as number)
            onMiscellaneousChanged(value as number)
          }} />
      </div> */}
  </div>
  )
}