import { Box, ButtonBase, Link } from "@mui/material"
import LinkEnergy from "./energisystem.png"
import LinkResource from "./ressourcesystem.png"
import { styles } from "./StartPage.style"

export const StartPage = () => {
  const energySystemUrl = window.location.href.replace('startPage=true', 'startPage=false')
  const returnUrl = window.location.href

  return (
    <Box sx={styles.container}>
      <Box sx={styles.inner}>
        <ButtonBase sx={styles.buttonContainer}>
          <Link
            href={energySystemUrl} 
            sx={styles.link}>
            <img src={LinkEnergy} alt="link energisystem" style={styles.linkImg} />
          </Link>
        </ButtonBase>
        <ButtonBase sx={styles.buttonContainer}>
          <Link 
            href={`https://reel-lolland.dk/energi-og-ressourcesystem/?return_url=${returnUrl}`}
            sx={styles.link}
          >
            <img src={LinkResource} alt="link ressourcesystem" style={styles.linkImg} />
          </Link>
        </ButtonBase>
      </Box>
    </Box>
  )
}
