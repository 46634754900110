import { valueWithDecimalsAndLocale } from '../../util/format-utils'
import { styles } from './ProductionSurplus.style'

interface ProductionSurplusProps {
    production: number
    consumption: number
    decimals?: number
    unit?: string
  }

export const ProductionSurplus = ({ 
    production,
    consumption,
    unit = 'MWh',
    decimals = 0
  }: ProductionSurplusProps) => {
  const surplus = (production - consumption)
  const surplusStyle = surplus < 0 ? {color: 'red'} : {color: 'green'}
  const surplusComponent = <span style={surplusStyle}>{isNaN(surplus) ? '-' : valueWithDecimalsAndLocale(surplus, decimals)}</span>
  return (
    <div style={styles.totalValue}>
      {`Produktion af grøn strøm: ${valueWithDecimalsAndLocale(production, decimals)} ${unit}`}
      <br/>
      {`Forbrug: ${valueWithDecimalsAndLocale(consumption, decimals) ?? '-'} ${unit}`}
      <br/>
      <b> {`${surplus < 0 ? 'Underskud' : 'Overskud'} af grøn strøm: `} {surplusComponent} {unit}</b>
    </div>
  )
}
