import logoReel from './../../../img/reel_logo.png'
import logoAI from './../../../img/ai_logo.png'
import { Dialog, IconButton, Tab } from '@mui/material'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import { useState } from 'react'
import { styles } from './InfoDialog.style'
import CloseIcon from '@mui/icons-material/Close'
import textsDa from '../../../data/da.json'

interface InfoDialogProps {
  open: boolean
  onClose: () => void
}

interface TutorialSection {
  title: string
  body: string
}

export const InfoDialog = ({ open, onClose }: InfoDialogProps) => {
  const [tabValue, setTabValue] = useState<string>('1')

  const tutorial: TutorialSection[] = [
    {
      title: textsDa.tutorialExploreTitle,
      body: textsDa.tutorialExploreBody
    },
    {
      title: textsDa.tutorialAdjustTitle,
      body: textsDa.tutorialAdjustBody
    },
    {
      title: textsDa.tutorialMapTitle,
      body: textsDa.tutorialMapBody
    },
    {
      title: textsDa.tutorialMobileTitle,
      body: textsDa.tutorialMobileBody
    }
  ]

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth='md'
    >
      <IconButton
        title='Luk'
        onClick={onClose}
        size= {'small'}
        style={styles.closeButton}
      >
        <CloseIcon/>
      </IconButton>
      <TabContext value={tabValue}>
        <TabList onChange={(event, newValue) => setTabValue(newValue)}>
          <Tab label='Introduktion' value='1' />
          <Tab label='Vejledning' value='2' />
        </TabList>
        <TabPanel value='1'>
          <div>
            {textsDa.introduction}
          </div>
          <div style={styles.logos}>
            <img src={logoAI} alt='Alexandra Instituttet logo' style={styles.logo} />
            <img src={logoReel} alt='reel logo' style={styles.logo} />
          </div>
        </TabPanel>
        <TabPanel value='2'>
          {tutorial.map((t, i) => (
            <div key={i}>
              <h4>{t.title}</h4>
              <p> 
                {t.body}
              </p>
            </div>
          ))}
        </TabPanel>
      </TabContext>
    </Dialog>
  )
}

