import { CircularProgress } from '@mui/material'
import { styles } from './LoadingIndicator.style'

interface LoadingIndicatorProps {
  padding?: string
  loadingTexts: string[]
}

export const LoadingIndicator = ({ padding = '0 0 0 0', loadingTexts }: LoadingIndicatorProps) => {
  return (
    <div style={styles.loadingContainer}>
      {loadingTexts.map((text: string, i: number) =>
        <div key={'i' + i} style={{ ...styles.loadingItem, padding }}>
          <CircularProgress size={'16px'}/>
          <span style={styles.loadingText}>{text}</span>
        </div>
      )}
    </div>
  )
}
