import { styles } from "./Dashboard.style"
import { SlidingDateSelector, IconSlider, LabelPosition, SliderOrientation, HorizontalModeValuePosition } from "dataviz-lib"
import { Chart } from '../chart/Chart'
import { useCallback, useState, useEffect, useMemo } from 'react'
// import { ReactComponent as Sun } from './../../img/sun.svg'
import { ReactComponent as StoneStorage } from './../../img/stenlager_ny_e.svg'
import './styles.css';
import { Box, CircularProgress, Dialog, IconButton, useMediaQuery, Button as MUIButton } from '@mui/material'
import exportImportWindMap from '../../data/animation/Eksport_import_wind_lolland.json'
import exportNoWindMap from '../../data/animation/Eksport_minus_wind_lolland.json'
import importImportWindMap from '../../data/animation/Import_all_lolland.json'
import importNoWindMap from '../../data/animation/Import_minus_wind_lolland.json'
import textsDa from '../../data/da.json'
import { useLottie } from "lottie-react"
import { colors } from '../../colors'
import { RawDataPointProduction, RawDataPointConsumption, getProductionData, getConsumptionData } from "../../api/EnergiDataApi"
import productionJson from '../../data/productionmunicipalityhour_300822.json'
import consumptionJson from '../../data/consumptionindustry_300822.json'
import {Help, Info} from '@mui/icons-material'
import { DataPointConsumption, DataPointProduction } from "../chart/Chart.types"
// import hand from './../../img/hand.gif'
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator"
import { BundleMode, DateMode, DialogData, SelectedDate, Texts } from "./Dashboard.types"
import { ConsumptionSliders } from "../consumptionSliders/ConsumptionSliders"
import { Forecast } from "../consumptionSliders/ConsumptionSliders.types"
import { bundleRawData, getDateMode, calculateStoneStorageValue, rawConsumptionsToDataPoint, rawProductionsToDataPoint, selectedDateToBundleMode, sumRawBundlesToDataPoints, calculateTotal, getInitialDate, getQuickFetchDate, countSelfSufficientHours } from "./util"
import { InfoDialog } from "./infoDialog/InfoDialog"
import { ProductionSliders } from "../productionSliders/ProductionSliders"
import CloseIcon from '@mui/icons-material/Close'
import { ProductionSurplusWithSufficiency } from "../productionSurplusWithSufficiency/ProductionSurplusWithSufficiency"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type DashboardProps = {
  backLink?: string
}

export const Dashboard = ({ backLink } : DashboardProps) => {
  const isBigScreen = useMediaQuery('(min-width: 1500px)')

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogData, setDialogData] = useState<DialogData | undefined>(undefined)

  const [infoDialogOpen, setInfoDialogOpen] = useState(true)

  const [stoneStorage, setStoneStorage] = useState<number>(0)
  const [extraSun, setExtraSun] = useState<number>(0)
  const [extraWind, setExtraWind] = useState<number>(0)

  const initialDate = getInitialDate()

  //MW
  const [extraHeatSupplyConsumption, setExtraHeatSupplyConsumption] = useState<number>(0)
  const [extraTransportConsumption, setExtraTransportConsumption] = useState<number>(0)
  const [extraPowerToXConsumption, setExtraPowerToXConsumption] = useState<number>(0)

  // This is not used for now
  const [extraMiscellaneousConsumption, setExtraMiscellaneousConsumption] = useState<number>(0)

  const [rawProductionData, setRawProductionData] = useState<RawDataPointProduction[]>([])
  const [rawConsumptionData, setRawConsumptionData] = useState<RawDataPointConsumption[]>([])

  const [currentConsumptionData, setCurrentConsumptionData] = useState<DataPointConsumption[]>([])
  const [currentProductionData, setCurrentProductionData] = useState<DataPointProduction[]>([])

  const [isLoadingConsumptionData, setIsLoadingConsumptionData] = useState<boolean>(true)
  const [isLoadingProductionData, setIsLoadingProductionData] = useState<boolean>(true)

  const [yearlyProduction, setYearlyProduction] = useState<DataPointProduction>()
  const [yearlyConsumption, setYearlyConsumption] = useState<DataPointConsumption>()

  const [datePeriod, setDatePeriod] = useState<DateMode>(DateMode.DAY)

  const [dateHasImport, setDateHasImport] = useState<boolean>(false)

  const [dateHasOffshoreWind, setDateHasOffshoreWind] = useState<boolean>(true)

  const [lollandMap, setLollandMap] = useState<any>(importImportWindMap)

  const [selectedDate, setSelectedDate] = useState<SelectedDate>(initialDate)

  const [selfSufficientHoursYearly, setSelfSufficientHoursYearly] = useState<number>(0)

  const hasData = (currentProductionData ?? []).length > 0 && (currentConsumptionData ?? []).length > 0

  const isLoadingData = isLoadingProductionData || isLoadingConsumptionData

  const updateDate = useCallback((date) => setSelectedDate(date), [setSelectedDate])

  const { View: map } = useLottie({
    animationData: lollandMap,
    loop: true,
    autoplay: true,
    style: {
      width: '100%'
    }
  })

  const getExtraProductionMW = useMemo((): number => extraSun + extraWind, [extraSun, extraWind])
  const getExtraConsumptionMW = useMemo((): number => extraTransportConsumption + extraPowerToXConsumption + extraHeatSupplyConsumption + extraMiscellaneousConsumption, [extraHeatSupplyConsumption, extraMiscellaneousConsumption, extraPowerToXConsumption, extraTransportConsumption])

  useEffect(() => {
    setDatePeriod(getDateMode(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    setExtraHeatSupplyConsumption(0)
    setExtraMiscellaneousConsumption(0)
    setExtraPowerToXConsumption(0)
    setExtraTransportConsumption(0)
    setExtraSun(0)
    setExtraWind(0)
  }, [datePeriod])
  
  useEffect(() => {
    if(dateHasOffshoreWind) {
      if(dateHasImport) {
        // Set IMPORT map WITH offshore wind
        setLollandMap(importImportWindMap) 
      }
      else {
        // Set EXPORT map WITH offshore wind
        setLollandMap(exportImportWindMap) 
      }
    }
    else {
      if(dateHasImport) {
        // Set IMPORT map WITHOUT offshore wind
        setLollandMap(importNoWindMap) 
      }
      else {
        // Set EXPORT map WITHOUT offshore wind
        setLollandMap(exportNoWindMap) 
      }
    }
  }, [dateHasOffshoreWind, dateHasImport])

  useEffect(() => {
    setSelfSufficientHoursYearly(countSelfSufficientHours(rawConsumptionData, rawProductionData, selectedDate, DateMode.YEAR))
  }, [isLoadingData, rawConsumptionData, rawProductionData, selectedDate])

  useEffect(() => {
    const productionData = sumRawBundlesToDataPoints(rawProductionsToDataPoint, bundleRawData(selectedDate, BundleMode.YEARS, rawProductionData)).find(dp => dp.timeLabel === selectedDate.year.toString())
    setYearlyProduction(productionData)
  }, [isLoadingData, rawProductionData, selectedDate])

  useEffect(() => {
    const consumptionData = sumRawBundlesToDataPoints(rawConsumptionsToDataPoint, bundleRawData(selectedDate, BundleMode.YEARS, rawConsumptionData)).find(dp => dp.timeLabel === selectedDate.year.toString())
    setYearlyConsumption(consumptionData)
  }, [isLoadingData, rawConsumptionData, selectedDate])

  useEffect(() =>
    { 
      const productionData = sumRawBundlesToDataPoints(rawProductionsToDataPoint, bundleRawData(selectedDate, selectedDateToBundleMode(selectedDate), rawProductionData), getDateMode(selectedDate) === DateMode.DAY ? 1 : 0)
      setCurrentProductionData(productionData)
      setDateHasOffshoreWind(productionData.reduce((prev, curr) => prev + (curr?.offshoreWind ?? 0), 0 ) > 0)
    },
    [selectedDate, rawProductionData])

  useEffect(() =>
  {  
    const consumptionData = sumRawBundlesToDataPoints(rawConsumptionsToDataPoint, bundleRawData(selectedDate, selectedDateToBundleMode(selectedDate), rawConsumptionData), getDateMode(selectedDate) === DateMode.DAY ? 1 : 0)
    setCurrentConsumptionData(consumptionData)
  },
    [selectedDate, rawConsumptionData])

  useEffect(() => {
    const stoneStorageMW = calculateStoneStorageValue(getDateMode(selectedDate), stoneStorage) * currentProductionData.length
    const extraConsumptionMW = getExtraConsumptionMW * currentConsumptionData.length
    const extraProductionMW = getExtraProductionMW * currentProductionData.length
    setDateHasImport(calculateTotal(currentConsumptionData, currentProductionData, stoneStorageMW, extraConsumptionMW, extraProductionMW) < 0)
  }, [currentConsumptionData, currentProductionData, getExtraConsumptionMW, selectedDate, stoneStorage, getExtraProductionMW])

  const getSliderInfoTexts = (text: Texts): {title: string, body: string}[] => {
    switch(text) {
      case Texts.CONSUMPTION:
        return textsDa.consumption
      case Texts.PRODUCTION:
        return textsDa.production
      case Texts.STORAGE:
        return textsDa.storage
      default: 
        return [{title: "", body: ""}]
    }
  }

  const getSliderHeader = (headerText: string, infoSections: {title: string, body: string}[]) => <div style={styles.infoHeader}>{headerText}<IconButton title={headerText} onClick={() => {
    setDialogData({
      title: headerText,
      sections: infoSections
    })
    setDialogOpen(true)
    }}
    size= {'small'} 
  > 
    <Info/>
  </IconButton></div>


  useEffect(() => {
    const fullDate = "2021-01-01T00:00"
    const quickDate = getQuickFetchDate(initialDate) ?? fullDate
    getProductionData(quickDate).then(data => setRawProductionData(data)).finally(() => 
      getProductionData(fullDate).then(data => setRawProductionData(data)).catch((err) => {
        console.error('error fetching production data, using offline')
        setRawProductionData(productionJson.result.records)
      }).finally(() => setIsLoadingProductionData(false))
    )


    getConsumptionData(quickDate).then(data => setRawConsumptionData(data)).finally(() => 
      getConsumptionData(fullDate).then(data => setRawConsumptionData(data)).catch((err) => {
        console.error('error fetching consumption data, using offline')
        setRawConsumptionData(consumptionJson.result.records)
      }).finally(() => setIsLoadingConsumptionData(false))
    )
  }, [])

  const productionSliders = 
    <ProductionSliders 
      onWindValueChanged = {(val) => setExtraWind(val)} 
      onSunValueChanged = {(val) => setExtraSun(val)} 
      dateMode={datePeriod} 
      isBigScreen={isBigScreen}
    />

  const consumptionSliders = 
    <ConsumptionSliders 
      onHeatSupplyChanged={(val) => setExtraHeatSupplyConsumption(val)} 
      onMiscellaneousChanged={(val) => setExtraMiscellaneousConsumption(val)} 
      onPowerToXChanged={(val) => setExtraPowerToXConsumption(val)} 
      onTransportChanged={(val) => setExtraTransportConsumption(val)} 
      forecast={Forecast.end} 
      dateMode={datePeriod} 
      isBigScreen={isBigScreen}
    />

  const infoAndReset =
    <div style={styles.infoAndReset}>
      <Box mr={1}>
        <IconButton
          title='Information'
          onClick={() => setInfoDialogOpen(true)}
          size= {'small'} 
        >
          <Help/>
        </IconButton>
      </Box>
      {backLink !== undefined && 
        <Box mr={1}>
          <MUIButton onClick={() => window.location.href = backLink} variant="outlined" startIcon={<ArrowBackIcon />}>
            Gå tilbage
          </MUIButton>
        </Box>
      }
      <MUIButton onClick={() => window.location.reload()} style={styles.resetButton}>
        Nulstil alt
      </MUIButton>
    </div>

  return (
    <div style={{
      ...styles.main,
      flexDirection: isBigScreen ? 'row' : 'column',
    }}>

      <div style={styles.left}>
        
        <div style={{
          ...styles.leftTop,
          fontSize: isBigScreen ? '38px' : '24px',
          padding: isBigScreen ? '16px 32px 0 32px' : '8px 8px 16px 8px'
        }}>LOLLANDS ENERGISYSTEM</div>
        
        <div style={{
          ...styles.leftUpper,
          padding: isBigScreen ? '0 32px 0 32px' : '0 8px 0 8px'
        }}>
          {map}
        </div>
        
        <div style={{
          ...styles.leftLower,
          padding: isBigScreen ? '32px 0 32px 0' : '8px 0 8px 0'
        }}>

          {!isBigScreen &&
            <div style={styles.horizontalSlidersContainer}>

              <div style={styles.horizontalSliderHeader}>{getSliderHeader("PRODUKTION", getSliderInfoTexts(Texts.PRODUCTION))}</div>
              {productionSliders}

              <div style={styles.horizontalSliderGroup}>
                <div style={styles.horizontalSliderHeader}>{getSliderHeader("LAGRING", getSliderInfoTexts(Texts.STORAGE))}</div>
                <div style={styles.horizontalSlider}>
                  <IconSlider 
                    stepSize={1}
                    valueInterval={{min: 0, max: 5}}
                    valueDisplay= {{valueFormatter: (val: number | number[]) => '+' + val + ' anlæg', horizontalModePos: HorizontalModeValuePosition.label}} 
                    value={stoneStorage}
                    label={{value:'Stenlager', position: LabelPosition.bottom}}
                    orientation = {SliderOrientation.horizontal}
                    onChange={(event, newValue) => setStoneStorage(newValue as number)} />
                </div>
              </div>

              <div style={styles.horizontalSliderHeader}>{getSliderHeader("FORBRUG", getSliderInfoTexts(Texts.CONSUMPTION))}</div>
              {consumptionSliders}
            </div>
          }

          {isBigScreen &&
            <div style={styles.sliderGroups}>

              <div style={styles.verticalSliderGroup}>
                <div style={styles.verticalSliderHeader}>{getSliderHeader("PRODUKTION", getSliderInfoTexts(Texts.PRODUCTION))}</div>
                {productionSliders}
              </div>

              <div style={styles.verticalSliderGroup}>
                <div style={styles.verticalSliderHeader}>{getSliderHeader("LAGRING", getSliderInfoTexts(Texts.STORAGE))}</div>
                <div style={styles.verticalSlidersContainer}>
                  <IconSlider 
                    stepSize={1}
                    valueInterval={{min: 0, max: 5}}
                    valueDisplay= {{valueFormatter: (val: number | number[]) => '+' + val + ' anlæg'}}
                    value={stoneStorage}
                    label={{value:'Stenlager', position: LabelPosition.bottom}}
                    orientation = {SliderOrientation.vertical}
                    icon={<StoneStorage style={styles.sliderIcon}/>}
                    onChange={(event, newValue) => setStoneStorage(newValue as number)} />
                </div>
              </div>

              <div style={styles.verticalSliderGroup}>
                <div style={styles.verticalSliderHeader}>{getSliderHeader("FORBRUG", getSliderInfoTexts(Texts.CONSUMPTION))}</div>
                {consumptionSliders}
              </div>
            </div>
          }
        </div>

        {isBigScreen &&
          <div style={{
            ...styles.leftBottom,
          }}>
            {infoAndReset}
          </div>
        }

      </div>

      <div style={styles.right}>
    
        <div style={{ padding: isBigScreen ? '16px 32px 16px 32px' : '8px 8px 8px 8px' }}>
          <div style={styles.chartTitle}>PRODUKTION OG FORBRUG</div>
          <div style={styles.chartSubTitle}>Tryk på grafen for at se mere</div>
        </div>

        <div style={{
          //TODO: chart does not scale down, only up - https://github.com/recharts/recharts/issues/172
          height: isBigScreen ? 0 : '90vh',
          flex: isBigScreen ? 1 : 'auto',
          padding: isBigScreen ? '32px 64px 0 0' : '8px 8px 0 8px'
        }}>
          {hasData ?
            <Chart
              isBigScreen={isBigScreen}
              dateMode={getDateMode(selectedDate)}
              stoneStorage={calculateStoneStorageValue(getDateMode(selectedDate), stoneStorage)}
              extraSun={extraSun}
              extraWind={extraWind}
              extraConsumptionMwh={getExtraConsumptionMW}
              currentConsumptionData={currentConsumptionData}
              currentProductionData={currentProductionData}
            />
            : (isLoadingData && !hasData) ? <div style={styles.loading}> <CircularProgress /> <span style={styles.loadingLabel}>Henter data...</span></div> 
            : <div style={styles.noDataBox}> Ingen data tilgængelig for den valgte periode </div>
          }
        </div>

        <div style={{
          ...styles.rightMiddle,
          flexDirection: isBigScreen ? (isLoadingData ? 'row-reverse' : 'row') : 'column',
          padding: isBigScreen ? '16px 64px 0 64px' : '16px 0 32px 24px'
        }}>
          {isLoadingData ?
            <LoadingIndicator
              padding={isBigScreen ? '0 0 0 0' : '0 0 16px 0'}
              loadingTexts={["Henter resterende data..."]}
            />
          :
          <ProductionSurplusWithSufficiency
              periodName = "året"
              productionSurplus = {{
                production: (yearlyProduction?.solar ?? 0) + (yearlyProduction?.combinedWind ?? 0),
                consumption: yearlyConsumption?.consumption ?? 0
              }}
              selfSufficiency= {{
                selfSufficciencyHours: selfSufficientHoursYearly,
                period: DateMode.YEAR,
                selectedDate: selectedDate
              }}
            />
          }
        </div>

        <div style={{
          ...styles.rightLower,
          padding: isBigScreen ? '32px 64px 32px 64px' : '8px 24px 32px 24px'
        }}>
          <div style={styles.dateTitle}>SE DATA FOR</div>
          <div style={styles.dateSliderContainer}>
            <SlidingDateSelector
              compact
              labelColor='black'
              maxYear={new Date().getFullYear()}
              minYear={2021}
              onDateUpdated={updateDate}
              primaryButtonColor={colors.lightGreen}
              primaryButtonSelectedColor={colors.darkGreen}
              secondaryButtonColor='#3d84ab'
              disableTodaySelection
              initialDate={initialDate}
            />
          </div>
        </div>

        {!isBigScreen &&
          infoAndReset
        }

      </div>

      {dialogOpen &&
        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen} maxWidth='md'>
          <IconButton
            title='Luk'
            onClick={() => setDialogOpen(false)}
            size= {'small'}
            style={styles.dialogCloseButton}
          >
            <CloseIcon />
          </IconButton>
          <Box p={2} style={styles.dialog}>
            {dialogData?.img && <Box p={1} style={styles.dialogLeft}>
              {dialogData?.img}
            </Box>}
            <Box pt={1} pl={2} pr={2} style={styles.dialogRight}>
              <Box mb={2} style={styles.dialogRightTop}>{dialogData?.title}</Box>
              { dialogData?.sections.map((t, i) => (
                  <div key={i}>
                    <h4>{t.title}</h4>
                    <p> 
                      {t.body}
                    </p>
                  </div>
                )) }
              <Box>{dialogData?.content}</Box>
            </Box>
          </Box>
        </Dialog>
      }
      {infoDialogOpen &&
        <InfoDialog
          open={infoDialogOpen}
          onClose={() => setInfoDialogOpen(false)}
        />
      }
    </div>
  )
}
