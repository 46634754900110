import { styles } from "./ProductionSliders.style"
import { ReactComponent as SolarUnit } from './../../img/solceller_ny_e.svg'
import { ReactComponent as WindFarm } from './../../img/windmill_ny_e.svg'
import { useCallback, useEffect, useState } from "react"
import { HorizontalModeValuePosition, IconSlider, LabelPosition, SliderOrientation } from "dataviz-lib"
import { ProductionSlidersProps, SliderConf } from "./ProductionSliders.types"
import { DateMode } from "../dashboard/Dashboard.types"
import { valueWithDecimalsAndLocale } from "../../util/format-utils"

// MWh - fra Sweco analyse
const FORECASTS = {
  wind: 2848984, // 1052220 uden fremskrevet produktion
  sun: 107801 // 48955 uden fremskrevet produktion
}

export const ProductionSliders = ({onWindValueChanged, onSunValueChanged, dateMode, isBigScreen = true, steps = 10}: ProductionSlidersProps) => {
  const [windValue, setWindValue] = useState<number>(0)
  const [sunValue, setSunValue] = useState<number>(0)

  const [sunConf, setSunConf] = useState<SliderConf>({max: 0, stepSize: 0})
  const [windConf, setWindConf] = useState<SliderConf>({max: 0, stepSize: 0})

  const valueDisplay = {
    valueFormatter: (val: number | number[]) => '+' + valueWithDecimalsAndLocale(val as number, 0) + ' MWh',
    horizontalModePos: isBigScreen ? HorizontalModeValuePosition.end : HorizontalModeValuePosition.label
  }

  const periodValueFromTotalValue = useCallback((value: number): number | undefined => {
    switch(dateMode) {
      case DateMode.DAY:
        return value/8760;
      case DateMode.MONTH:
        return value/365
      case DateMode.YEAR:
        return value/12
      default:
        return undefined
    }
  }, [dateMode])

  useEffect(() => {
    const windPeriodValue = (periodValueFromTotalValue(FORECASTS.wind)) ?? 0
    setWindConf({max: Math.round(windPeriodValue), stepSize: Math.ceil(windPeriodValue/steps)})
    setSunValue(0)

    const sunPeriodValue = (periodValueFromTotalValue(FORECASTS.sun)) ?? 0
    setSunConf({max: Math.round(sunPeriodValue), stepSize: Math.ceil(sunPeriodValue/steps)})
    setWindValue(0)
    
  }, [dateMode, periodValueFromTotalValue, steps])

  return (
    <div style={{...styles.slidersContainer, flexDirection: isBigScreen ? 'row' : 'column',  height: isBigScreen ? '100%' : 'auto', width: isBigScreen ? 'auto' : '100%'}}>
      <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '120px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={sunConf.stepSize}
          valueInterval={{min: 0, max: sunConf.max}}
          value={sunValue}
          label={{value:'Solcelleanlæg', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon= {isBigScreen ? <SolarUnit style={styles.sliderIcon}/> : undefined}
          onChange={(event, value) => {
            setSunValue(value as number)
            onSunValueChanged(value as number)
          }} />
      </div>
      <div style={styles.slider}>
        <IconSlider
          size={{valueWidth: isBigScreen ? '120px' : 'auto'}}
          valueDisplay= {valueDisplay}
          stepSize={windConf.stepSize}
          valueInterval={{min: 0, max: windConf.max}}
          value={windValue}
          label={{value:'Vind', position: LabelPosition.bottom}}
          orientation = {isBigScreen ? SliderOrientation.vertical : SliderOrientation.horizontal}
          icon={isBigScreen ? <WindFarm style={styles.sliderIcon}/> : undefined}
          onChange={(event, value) => {
            setWindValue(value as number)
            onWindValueChanged(value as number)
          }} />
      </div>
  </div>
  )
}