import { valueWithDecimalsAndLocale } from '../../util/format-utils'
import { DateMode, SelectedDate } from '../dashboard/Dashboard.types'
import { styles } from './ProductionSurplusWithSufficiency.style'
import { ProductionSurplus } from "../productionSurplus/ProductionSurplus"

interface ProductionSurplusWithSufficiencyProps {
    periodName: string
    productionSurplus: {
      production: number
      consumption: number
      decimals?: number
      unit?: string
    }
    selfSufficiency: {
      selfSufficciencyHours: number
      period: DateMode
      selectedDate: SelectedDate
    }
}

export const ProductionSurplusWithSufficiency = ({
  periodName,
  productionSurplus,
  selfSufficiency
  }: ProductionSurplusWithSufficiencyProps) => {

  const getHoursInPeriod = (): number => {
    if(selfSufficiency.period === DateMode.YEAR) {
      const currentDate = new Date()

      // Calculate and return the hours between the current date or end of the year and the beginning of the year.
      const toDate = currentDate.getFullYear() === selfSufficiency.selectedDate.year ? currentDate.getTime() : new Date(selfSufficiency.selectedDate.year + 1, 0, 0).getTime()
      return Math.round(Math.abs(toDate - new Date(selfSufficiency.selectedDate.year, 0, 0).getTime()) / 36e5)
    }
    
    if(selfSufficiency.period === DateMode.MONTH) {
      return new Date(selfSufficiency.selectedDate.year, (selfSufficiency.selectedDate.month ?? 0) + 1, 0).getDate() * 24
    }
    
    return 24
  }

  const percentageComponent = <span style={styles.percentageColor}>{
      isNaN(selfSufficiency.selfSufficciencyHours / getHoursInPeriod()) ? 
        '-' : 
        Number(valueWithDecimalsAndLocale((selfSufficiency.selfSufficciencyHours / getHoursInPeriod()) * 100)) < 1 ? '< 1' : 
        valueWithDecimalsAndLocale((selfSufficiency.selfSufficciencyHours / getHoursInPeriod()) * 100, 1)
    }%</span>
  
  return (
    <div style={styles.textColor}>
      <u>
        {`Nøgletal om Lollands energisystem (faktiske tal for hele ${periodName}):`}
      </u>
      <ProductionSurplus
        production={productionSurplus.production}
        consumption={productionSurplus.consumption}
        unit={productionSurplus.unit}
        decimals={productionSurplus.decimals}
      />
      {`Forbruget kunne dækkes af grøn strøm`} {percentageComponent} {`af tiden (${valueWithDecimalsAndLocale(selfSufficiency.selfSufficciencyHours)} timer)`}
    </div>
  )
}
