import  { MultiAreaChart } from 'dataviz-lib'
import { styles } from './Chart.style'
import { ChartProps} from './Chart.types'
import { DateMode } from '../dashboard/Dashboard.types'
import { valueWithDecimalsAndLocale } from '../../util/format-utils'

const CustomTooltip = ({ active, payload, label, mode, extraConsumptionMwh}: any) => {
  if (active && payload && payload.length) {
    const sun = parseFloat(payload[0].payload['sun'])
    const sunExtra = parseFloat(payload[0].payload['sunExtra'])
    const windExtra = parseFloat(payload[0].payload['windExtra'])
    const wind = parseFloat(payload[0].payload['wind'])
    const stoneStorage = payload[0].payload['stoneStorage'] !== undefined ? parseFloat(payload[0].payload['stoneStorage']) : 0
    const productionTotal = sun + wind + stoneStorage + sunExtra + windExtra
    const consumptionWithExtra = parseFloat(payload[0].payload['consumption'])
    const consumption = consumptionWithExtra - extraConsumptionMwh
    const surplus = productionTotal - consumptionWithExtra
    const surplusStyle = surplus < 0 ? {color: 'red'} : {color: 'green'}
    const surplusComponent = <span style={surplusStyle}>{isNaN(surplus) ? '-' : parseFloat(surplus.toString()).toFixed(2).replace('.', ',')}</span>
    return (
      <div style={{ background: '#fefefe', padding: 8, borderRadius: 4, boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}>
        <b>{mode !== DateMode.DAY ? label : `${(label-1).toString().padStart(2, '0')}:00 - ${label.padStart(2, '0')}:00`}</b>
        <br/>
        <i style={{ fontSize: 12 }} >Enhed: MWh</i>
        <br/>
        {`Sol: ${isNaN(sun) ? '-' : valueWithDecimalsAndLocale(sun as number, 2)}`}
        <br/>
        {`Sol tilføjet: ${isNaN(sunExtra) ? '-' : valueWithDecimalsAndLocale(sunExtra as number, 2)}`}
        <br/>
        {`Vind: ${isNaN(wind) ? '-' : valueWithDecimalsAndLocale(wind as number, 2)}`}
        <br/>
        {`Vind tilføjet: ${isNaN(windExtra) ? '-' : valueWithDecimalsAndLocale(windExtra as number, 2)}`}
        <br/>
        {`Stenlager tilføjet: ${valueWithDecimalsAndLocale(stoneStorage as number, 2)}`}
        <br/>
        <b>{`Total produktion: ${isNaN(productionTotal) ? '-' : valueWithDecimalsAndLocale(productionTotal as number, 2)}`}</b>
        <hr/>
        {`Forbrug: ${isNaN(consumption) ? '-' : valueWithDecimalsAndLocale(consumption as number, 2)}`}
        <br/>
        {`Forbrug tilføjet: ${isNaN(extraConsumptionMwh) ? '-' : valueWithDecimalsAndLocale(extraConsumptionMwh as number, 2)}`}
        <br/>
        <b>{`Totalt forbrug: ${isNaN(consumptionWithExtra) ? '-' : valueWithDecimalsAndLocale(consumptionWithExtra as number, 2)}`}</b>
        <hr/>
        {surplus < 0 ? 'Underskud:' : 'Overskud:'} {surplusComponent}
      </div>
    )
  }
  return null
}

export const Chart = ({ dateMode, stoneStorage, extraSun, extraWind, currentConsumptionData, currentProductionData, extraConsumptionMwh, isBigScreen }: ChartProps) => {
  const colors = {
    deficit: '#c20327',
    sun: '#ffd22c',
    wind: '#48adbf',
    consumption: '#444',
    stone: '#777',
    sunExtra: '#dbac11',
    windExtra: '#258195'
  }
  
  const getYMax = () => {
    switch(dateMode) {
      case DateMode.DAY:
        return 1000
      case DateMode.MONTH:
        return 22000
      case DateMode.YEAR:
        return 480000
      default:
        return 0
    }
  }

  const areas = [
    {
      dot: false,
      fill: colors.deficit,
      key: 'consumption',
      label: 'Underskud',
      opacity: 0.5,
      stackIndex: 3,
      stroke: 'none',
      values: currentConsumptionData.map(d => d.consumption),
    },
    {
      label: `Stenlager tilføjet`,
      key: "stoneStorage",
      stackIndex: 2,
      stroke: 'none',
      fill: colors.stone,
      opacity: 1,
      values: currentProductionData.map(d => stoneStorage),
      dot: false,
    },
    {
      label: `Sol`,
      key: "sun",
      stackIndex: 2,
      stroke: 'none',
      fill: colors.sun,
      opacity: 1,
      values: currentProductionData.map(d => d.solar),
      dot: false,
    },
    {
      label: `Sol tilføjet`,
      key: "sunExtra",
      stackIndex: 2,
      stroke: 'none',
      fill: colors.sunExtra,
      opacity: 1,
      values: currentProductionData.map(d => d.solar !== undefined ? extraSun : undefined),
      dot: false
    },
    {
      label: `Vind`,
      key: "wind",
      stackIndex: 2,
      stroke: 'none',
      fill: colors.wind,
      opacity: 1,
      values: currentProductionData.map(d => d.combinedWind),
      dot: false,
    },
    {
      label: `Vind tilføjet`,
      key: "windExtra",
      stackIndex: 2,
      stroke: 'none',
      fill: colors.windExtra,
      opacity: 1,
      values: currentProductionData.map(d => d.combinedWind !== undefined ? extraWind : undefined),
      dot: false,
    }
  ]

  const getXAxisLabel = (timeLabel: string, mode: DateMode) => {
    const timeUnit = parseInt(timeLabel)
    switch (mode) {
      case DateMode.DAY:
        return timeLabel.length < 2 ? '0' + timeLabel : timeLabel
      case DateMode.MONTH:
        return timeLabel + '.'
      case DateMode.YEAR:
        return ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'][timeUnit - 1]
      default:
        return ''
    }
  }

  return (
    <>
      {isBigScreen && <div style={styles.chartYLabel}>MWh</div>}
      <MultiAreaChart
        margin={{
          left: isBigScreen ? 40 : 10
        }}
        legend={{
          show: true,
          height: isBigScreen ? 48 : 72
        }}
        tooltip={{
          trigger: "hover",
          active: true,
          customTooltip: <CustomTooltip extraConsumptionMwh={extraConsumptionMwh} mode={dateMode} />
        }}
        data={{
          xAxis: {
            values: currentProductionData.map(d => getXAxisLabel(d.timeLabel, dateMode))
          },
          yAxis: {
            min: 0,
            max: getYMax()
          },
          areas,
          lines: [
            {
              label: "Forbrug",
              key: "consumption",
              stroke: colors.consumption,
              width: 2,
              opacity: 1,
              values: currentConsumptionData.map(d => d.consumption !== undefined ? (d.consumption + extraConsumptionMwh) : d.consumption),
              dot: false,
            }
          ]
        }}
        legendDisplayZeroValues={false} />  
    </>
  )
}
